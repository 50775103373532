import * as React from 'react';
import { Box, MenuItem, Select } from '@mui/material';
import v from 'voca';

import {
  JAMarketTypes,
  JABetTypes,
  JAMarketTypesValue,
  JABetTypesValues,
} from '@/constants/jaialai';

import {
  // Styled Components
  GridCell,
  CellLabel,
  MarketTypeToggleLabel,
} from '../components';
import { MIN_HOLD_PERCENTAGE } from './MarketHeaderContent';
import { marketOddModifiers, marketOddsPayload } from '../marketHelper';
import { FIELD_TO_DEBUG, teamTypeToKey } from '../constants';
import round from 'lodash.round';
import cloneDeep from 'lodash.clonedeep';
import DebugLabelDisplay from '../DebugLabelDisplay';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';

const BetTypeHeaders = ({
  value,
  bet,
  betType,
  commonCellHeaderStyles,
  holdPercentagesOptions,
  setFieldValue,
  oddId,
}: any) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const headerConfig = {
    [JABetTypes.EVENT_WINNER]: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel moneyLineLabel moneyLineLabel moneyLineLabel moneyLineLabel blank blank blank blank blank blank blank blank"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'moneyLineLabel', text: 'Money Line' },
        { area: 'blank', text: '' },
        // { area: 'spreadLabel', text: 'Spread' },
        // { area: 'totalLabel', text: 'Total' },
      ],
    },
    [JABetTypes.WINS_NEXT_SERVE]: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel moneyLineLabel moneyLineLabel moneyLineLabel moneyLineLabel blank blank blank blank blank blank blank blank"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'moneyLineLabel', text: 'Money Line' },
        { area: 'blank', text: '' },
        // { area: 'spreadLabel', text: 'Spread' },
        // { area: 'totalLabel', text: 'Total' },
      ],
    },
    [JABetTypes.CORRECT_SETS]: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom holdPercentageCustom"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
      ],
    },
    [JABetTypes.TOTAL_SETS]: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel blank blank blank blank blank blank blank blank outcome3Label outcome3Label outcome3Label outcome3Label"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'blank', text: '' },
        { area: 'blank', text: '' },
        { area: 'outcome3Label', text: 'Outcome 3' },
      ],
    },

    [JABetTypes.MATCH_WINNER]: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel outcome1Label outcome1Label outcome1Label outcome1Label outcome2Label outcome2Label outcome2Label outcome2Label outcome3Label outcome3Label outcome3Label outcome3Label"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'outcome1Label', text: 'Outcome 1' },
        { area: 'outcome2Label', text: 'Outcome 2' },
        { area: 'outcome3Label', text: 'Outcome 3' },
      ],
    },
    default: {
      gridTemplateAreas: `"teamLabel teamLabel teamLabel teamLabel teamLabel teamLabel biasLabel biasLabel moneyLineLabel moneyLineLabel moneyLineLabel moneyLineLabel spreadLabel spreadLabel spreadLabel spreadLabel totalLabel totalLabel totalLabel totalLabel"`,
      labels: [
        { area: 'teamLabel', text: 'Team' },
        { area: 'biasLabel', text: 'Bias' },
        { area: 'moneyLineLabel', text: 'Money Line' },
        { area: 'spreadLabel', text: 'Spread' },
        { area: 'totalLabel', text: 'Total' },
      ],
    },
  };

  const { gridTemplateAreas, labels } = headerConfig[betType] || headerConfig.default;

  const isSubMarketLocked = marketOddsPayload({ odds: [{ bets: [bet] }] })?.every(
    (odd) => odd?.isSubMarketLocked,
  );

  const holdPercentage = React.useMemo(() => {
    let totalProbability = 0;
    Object.values(bet).map((bt: any) => {
      if (typeof bt !== 'string') {
        totalProbability += +bt?.[teamTypeToKey.homeTeam]?.probability || 0;
      }
    });

    return round(totalProbability);
  }, [bet]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(20, minmax(0, 1fr))',
        gridTemplateRows: 'repeat(1, minmax(0, 50px))',
        mt: 1,
        ...{ gridTemplateAreas }, // Spread the gridTemplateAreas
      }}
    >
      {labels.map(({ area, text }) => (
        <GridCell
          key={area}
          sx={{
            gridArea: area,
            borderTopLeftRadius: area === 'teamLabel' ? '0.25rem' : undefined,
            borderTopRightRadius: area === 'totalLabel' ? '0.25rem' : undefined,
            ...commonCellHeaderStyles,
          }}
        >
          <CellLabel>{text}</CellLabel>
        </GridCell>
      ))}

      {betType === JABetTypes.CORRECT_SETS && (
        <Box
          sx={{
            gridArea: 'holdPercentageCustom',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem',
            ...commonCellHeaderStyles,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <MarketTypeToggleLabel sx={{ fontWeight: 600 }}>HOLD PERCENTAGE:</MarketTypeToggleLabel>
            <DebugLabelDisplay row={bet} showFields={[FIELD_TO_DEBUG.betType]} />
            &nbsp;
            <Select
              id={`select-${v.kebabCase(
                JAMarketTypesValue[value?.marketType as keyof {}] || '',
              )}-${v.kebabCase(
                JABetTypesValues[JABetTypes.CORRECT_SETS as keyof {}],
              )}-holdPercentageCustom`}
              value={holdPercentage}
              onClick={(event) => {
                event.stopPropagation();
              }}
              onChange={(event) => {
                event.stopPropagation();
                const {
                  target: { value: val },
                } = event;
                const newHoldPercentage = Number(val);
                if (newHoldPercentage !== holdPercentage) {
                  const valCpy = cloneDeep(value);
                  if (+valCpy.marketType === JAMarketTypes.MATCH) {
                    const { bets } = valCpy;
                    const betsCpy = bets.filter(
                      (bt: any) => +bt?.betType !== JABetTypes.CORRECT_SETS,
                    );
                    const correctSets = bets.filter(
                      (bt: any) => +bt?.betType === JABetTypes.CORRECT_SETS,
                    );
                    valCpy.bets = correctSets;

                    const updatedMarket: any = marketOddModifiers({
                      marketOdds: valCpy,
                      newHoldPercentage,
                      oldHoldPercentage: holdPercentage,
                      defaultHoldPercentage: MIN_HOLD_PERCENTAGE,
                    });
                    const currentUpdatedBets = cloneDeep(updatedMarket.bets);
                    updatedMarket.bets = [...currentUpdatedBets, ...betsCpy].filter(Boolean);

                    setFieldValue(`odds[${oddId}]`, updatedMarket);
                  }
                }
              }}
              sx={{ height: 30, backgroundColor: '#FFFFFF' }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              disabled={isSubMarketLocked || isDisabledMatch}
            >
              {React.Children.toArray(
                holdPercentagesOptions.map((percent: number) => (
                  <MenuItem value={percent}>{percent}</MenuItem>
                )),
              )}
            </Select>{' '}
            &nbsp;%
          </Box>
          <Box>
            <MarketTypeToggleLabel
              id={`${v.kebabCase(
                JAMarketTypesValue[value?.marketType as keyof {}] || '',
              )}-${v.kebabCase(
                JABetTypesValues[JABetTypes.CORRECT_SETS as keyof {}],
              )}-margin-label`}
              sx={{ fontWeight: 600, color: '#72727E' }}
            >
              MARGIN: {holdPercentage - MIN_HOLD_PERCENTAGE} %
            </MarketTypeToggleLabel>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default BetTypeHeaders;
