import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import config from '@/config/vars';

export const initializeLogRocket = () => {
  if (config.logrocketAppId) {
    LogRocket.init(config.logrocketAppId, {
      console: {
        isEnabled: true,
        shouldAggregateConsoleErrors: true,
      },
    });

    setupLogRocketReact(LogRocket);

    // Set up auth state listener
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        LogRocket.identify(user.uid, {
          name: user.displayName || 'Unknown',
          email: user.email || 'no-email',
          environment: config.environment,
        });
      }
    });
  }
};

export default initializeLogRocket;
