import * as React from 'react';
import v from 'voca';
import { enqueueSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DeleteIcon from '@mui/icons-material/Delete';

import { ReactComponent as EventDateIcon } from '@/images/icons/event-date-icon.svg';
import { ReactComponent as EventLocationIcon } from '@/images/icons/event-location-icon.svg';
import { ReactComponent as EditIconBlue } from '@/images/icons/edit-icon-blue.svg';

import EventBreadcrumbs from '@/components/EventBreadcrumbs';
import EventDetailsModal from '@/components/EventDetailsModal';
import SimulationToggle from '@/components/SimulationToggle';
import DeleteConfirmDialog from '@/components/Dialogs/DeleteConfirmDialog';

import useDeleteEvent from '@/hooks/events/useDeleteEvent';

import { processEventStatusBadges } from '@/helpers/events';
import SPORT_TYPES from '@/constants/sportTypes';
import routes from '@/constants/routes';

import { invalidateCachedSports } from '@/helpers/cachedQueries';

import { IEvent } from '@/types/newTypes/event';

import { displayInPT } from '@/helpers/timeConverters';
import { WEB_SOCKET_EVENT_TYPES } from '@/constants/socket';
import { subscribeToAllEvents } from '@/utils/socketService';
import { JAI_ALAI_NAMESPACE } from '@/features/scores/JAIALAI';

const EventDetails = ({ event, sportType = '' }: { event: IEvent; sportType: string }) => {
  const {
    name = '',
    eventStatus = 0,
    startDate = '',
    endDate = '',
    eventLocation = '',
    tour,
    league,
    id,
  } = event;
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutateAsync: deleteEvent } = useDeleteEvent();
  const [showEditEventDetailsModal, setShowEditEventDetailsModal] = React.useState(false);
  const [showDeleteEventModal, setShowDeleteEventModal] = React.useState(false);
  const [dayStatus, setDayStatus] = React.useState(eventStatus || 0);

  const toggleShowDeleteEventModal = () => setShowDeleteEventModal((prev) => !prev);

  const { gameDateId = '' } = useParams();

  let gender = null;

  if ([SPORT_TYPES.SLS, SPORT_TYPES.WSL].includes(sportType)) {
    gender = tour?.gender || league?.gender || '';
  }

  const handleToggleShowEditEventDetailsModal = () =>
    setShowEditEventDetailsModal((prevVal) => !prevVal);

  const canEditEventDetails = React.useMemo(
    () => ![SPORT_TYPES.MASL, SPORT_TYPES.JAIALAI].includes(sportType),
    [sportType],
  );

  const handleDeleteEvent = () => {
    deleteEvent(
      {
        sport: sportType,
        eventId: id,
      },
      {
        onSettled: (_, err) => {
          if (!err) {
            enqueueSnackbar('Deleted event successfully!');
            invalidateCachedSports(queryClient);
            navigate(routes.events.home);
          }
        },
      },
    );
  };

  const handleEventRefresh = (data: any) => {
    if (SPORT_TYPES.MASL === sportType) {
      if (data?.events) {
        const currentEvents = data?.events;
        const currentSelectedEvt = currentEvents.find((evt: any) => evt?.id === id);
        setDayStatus(currentSelectedEvt?.eventStatus);
      }
    } else {
      if (data?.summary?.dayStatus) {
        setDayStatus(data.summary.dayStatus);
      }
    }
  };

  React.useEffect(() => {
    if (SPORT_TYPES.MASL === sportType) {
      setDayStatus(event?.eventStatus);
    }
  }, [event]);

  // This update is going on for when backend web socket trigger the status, it doesn't update
  React.useEffect(() => {
    const callback = (event: string, data: any) => {
      if (event === WEB_SOCKET_EVENT_TYPES.EVENT_REFRESH) {
        if (gameDateId === data.data.id) {
          handleEventRefresh(data.data);
          invalidateCachedSports(queryClient);
        }
      }
    };

    if ([SPORT_TYPES.JAIALAI, SPORT_TYPES.MASL].includes(sportType)) {
      subscribeToAllEvents(JAI_ALAI_NAMESPACE, callback);
    }
  }, [sportType, gameDateId]);

  return (
    <Grid
      container
      direction="column"
      sx={{ position: 'relative', marginTop: { xs: '6rem', sm: 0 } }}
      data-testid="event-details-wrapper"
    >
      {sportType !== SPORT_TYPES.MASL && (
        <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
          <EventBreadcrumbs event={event} />
        </Box>
      )}
      <Grid
        container
        sx={{
          flexDirection: { xs: 'column', sm: 'row' },
          gap: { xs: '1rem', sm: 0 },
          marginBottom: '1.625rem',
        }}
      >
        <Grid item xs={12} sm={canEditEventDetails ? 10 : 12}>
          <Typography
            sx={{
              marginBottom: '0.875rem',
              marginTop: '1rem',
              color: 'info.main',
              fontSize: '2rem',
              fontWeight: '500',
              display: 'inline-flex',
              alignItems: 'center',
            }}
            variant="h4"
          >
            {[name, gender && v.titleCase(gender)].filter(Boolean).join(' ')}
            &nbsp;
            {processEventStatusBadges(
              [SPORT_TYPES.JAIALAI, SPORT_TYPES.MASL].includes(sportType) ? dayStatus : eventStatus,
            )}
          </Typography>
          <Typography
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'info.main',
              fontSize: '0.875rem',
              lineHeight: '125%',
            }}
          >
            {/* In case of Jai Alai only display a single date, this likely applies for MASL as well */}
            {[SPORT_TYPES.MASL, SPORT_TYPES.JAIALAI].includes(sportType) && startDate && (
              <>
                <EventDateIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                {`${displayInPT(startDate)}`}
                &nbsp; &nbsp; &nbsp; &nbsp;
              </>
            )}

            {/* {![SPORT_TYPES.MASL, SPORT_TYPES.JAIALAI].includes(sportType) &&
              (!!startDate || !!endDate) && (
                <>
                  <EventDateIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                  {((startDate && !endDate) || startDate === endDate) &&
                    `${format(new Date(startDate),)}`}
                  {startDate &&
                    endDate &&
                    startDate !== endDate &&
                    `${format(new Date(startDate), 'LLL d')} - ${format(
                      new Date(endDate),
                      'LLL d, yyyy',
                    )}`}
                  &nbsp; &nbsp; &nbsp; &nbsp;
                </>
              )} */}

            {![SPORT_TYPES.MASL, SPORT_TYPES.JAIALAI].includes(sportType) && (
              <>
                {(endDate === null || !endDate) && startDate && (
                  <>
                    <EventDateIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                    {displayInPT(startDate)}
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </>
                )}
                {endDate !== null && endDate && (
                  <>
                    <EventDateIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                    {displayInPT(startDate) === displayInPT(endDate) ? (
                      `${displayInPT(startDate)}`
                    ) : (
                      <>
                        {displayInPT(startDate, 'MMM d')} -&nbsp;
                        {displayInPT(endDate)}
                      </>
                    )}
                    &nbsp; &nbsp; &nbsp; &nbsp;
                  </>
                )}
              </>
            )}

            {eventLocation && (
              <>
                <EventLocationIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                {eventLocation}
              </>
            )}
          </Typography>
        </Grid>
        {canEditEventDetails && (
          <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            item
            xs={12}
            sm={2}
            sx={{ alignItems: { xs: 'flex-start', sm: 'flex-end' } }}
          >
            <IconButton disableRipple onClick={handleToggleShowEditEventDetailsModal}>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: 'info.main',
                  fontSize: '0.8125rem',
                  lineHeight: '1.25rem',
                }}
              >
                <EditIconBlue style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                &nbsp;Edit event details
              </Typography>
            </IconButton>

            {[SPORT_TYPES.BYB, SPORT_TYPES.POWERSLAP].includes(sportType) && (
              <IconButton disableRipple onClick={toggleShowDeleteEventModal} id="delete-event">
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'error.main',
                    fontSize: '0.8125rem',
                    lineHeight: '1.25rem',
                  }}
                >
                  <DeleteIcon style={{ fontSize: '1.25rem', marginRight: '0.5rem' }} />
                  &nbsp;Delete event
                </Typography>
              </IconButton>
            )}

            {![
              SPORT_TYPES.F1,
              SPORT_TYPES.MOTOGP,
              SPORT_TYPES.MXGP,
              SPORT_TYPES.MOTOAMERICA,
              SPORT_TYPES.BYB,
              SPORT_TYPES.BKFC,
            ].includes(sportType) && <SimulationToggle sportType={sportType} />}
          </Grid>
        )}
      </Grid>

      {/* <PlayLiveStream /> */}

      <EventDetailsModal
        open={showEditEventDetailsModal}
        handleClose={handleToggleShowEditEventDetailsModal}
        event={event}
        sportType={sportType}
      />

      <DeleteConfirmDialog
        open={showDeleteEventModal}
        handleClose={() => toggleShowDeleteEventModal()}
        handleConfirm={() => {
          toggleShowDeleteEventModal();
          handleDeleteEvent();
        }}
        title={`Confirm Deletion`}
        body={
          <Typography sx={{ fontSize: '0.9rem' }}>
            Data will be deleted permanently, to confirm deletion, type <strong>DELETE</strong> in
            the text input field.
          </Typography>
        }
      />
    </Grid>
  );
};

export default EventDetails;
