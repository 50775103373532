import * as React from 'react';
import { Box, Typography } from '@mui/material';

const NoMatchData = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '.3rem',
        height: '60vh',
      }}
    >
      <Typography sx={{ textAlign: 'center', fontWeight: '500' }}>
        {'There’s no matches available for the selected date'}
      </Typography>
      <Typography sx={{ textAlign: 'center', fontWeight: '500' }}>
        {'Please try another date'}
      </Typography>
    </Box>
  );
};

export default NoMatchData;
