import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import {
  Box,
  Button,
  Grid,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import { zonedTimeToUtc } from 'date-fns-tz';
import { format as normalFormat } from 'date-fns';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DeleteIcon from '@mui/icons-material/Delete';

import useFetchEventScores from '@/hooks/events/useFetchEventScores';
import useAddBYBScores from '@/hooks/byb/useAddBYBScores';
import useUpdateBYBScores from '@/hooks/byb/useUpdateBYBScores';
import useDeleteBYBScores from '@/hooks/byb/useDeleteBYBScores';
import useFetchFightParticipants from '@/hooks/fights/useFetchFightParticipants';

import DeleteConfirmDialog from '@/components/Dialogs/DeleteConfirmDialog';
import CustomEndFightDialog from '@/components/Dialogs/CustomEndFightDialog';

import { FightStatusKeys, FightStatusNames, FightStatusValues } from '@/constants/fights';

import { displayInPT as format } from '@/helpers/timeConverters';

import JudgeIcon from '@/images/icons/judge-icon.svg';
import Winnerenabledicon from '@/images/icons/winner-enabled-icon.svg';

// import { data, athletesArray, weightOptions, decisionOptions } from './data';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionSummaryText,
  AccordionSummaryTextLg,
  processMatchStatusBadges,
  AccordionDtlsLabelText,
  ModSelect,
  ModAutocomplete,
  CounterButton,
  JudgeScores,
  ScoreLabels,
  ModDecimalField,
  CustomMultilineInput,
  CustomAthleteSelect,
} from './components';
import SPORT_TYPES from '@/constants/sportTypes';
import { useParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import { useQueryClient } from '@tanstack/react-query';

import { DEFAULT_TIMEZONE, WIN_BY_OPTIONS } from '@/constants/misc';

import keys from '@/constants/queryKeys';
import { getFirstLettersCombined, getNumberFromCharacters } from '@/helpers/misc';
import useFetchWeightClasses from '@/hooks/fightSportMisc/useFetchWeightClasses';
import { headToHeadTableStyles } from '@/utils/TableMisc';

const ARCHIVED = 1;

const BLUE_CORNER_COLOR = '#3071E9';
const RED_CORNER_COLOR = '#ED4C47';

const BLUE_CORNER_WIN_BY = '#5E6BDC';
const BLUE_CORNER_TEXT_WIN_BY = '#5E6BDC';

const RED_CORNER_WIN_BY = '#AD1827';
const RED_CORNER_TEXT_WIN_BY = '#AD1827';

const DEFAULT_JUDGE_OBJ = {
  score1: 0,
  score2: 0,
  name: null,
};

const DEFAULT_FIGHT = {
  id: '1',
  name: null,
  blueCornerAthlete: null,
  redCornerAthlete: null,
  scheduledRounds: 0,
  lastRound: null,
  startDate: null,
  endDate: null,
  heatStatus: 2,
  winner: null,
  referee: null,
  winBy: '',
  notes: '',
  weightClass: '',
  judge1: DEFAULT_JUDGE_OBJ,
  judge2: DEFAULT_JUDGE_OBJ,
  judge3: DEFAULT_JUDGE_OBJ,
};

const fightValidationSchema = Yup.object().shape({
  blueCornerAthlete: Yup.object()
    .shape({
      id: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .required('Blue Corner Athlete is required'),
  redCornerAthlete: Yup.object()
    .shape({
      id: Yup.string().required(),
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
    })
    .required('Red Corner Athlete is required'),
});

const FightContainer = ({
  scr,
  idx,
  values,
  setFieldValue,
  errors,
  ATHLETE_OPTIONS,
  WEIGHT_CLASSES,
  JUDGE_OPTIONS,
  REFEREE_OPTIONS,
  WIN_BY_OPTIONS,
  FIGHT_STATUS_OPTIONS,
  handleBlur,
  handleSaveOrEndFight,
  accordion,
  setAccordion,
  matchSummaryDateFormatter,
  toggleConfirmDeleteDialog,
  handleConfirmDelete,
  toggleCustomStartFightDialog,
  toggleCustomEndFightDialog,
}: any) => {
  const { redCornerAthlete, blueCornerAthlete } = React.useMemo(() => {
    const { redCornerAthlete: _redCornerAthlete, blueCornerAthlete: _blueCornerAthlete } =
      values?.scores[idx] || {};

    return { redCornerAthlete: _redCornerAthlete, blueCornerAthlete: _blueCornerAthlete };
  }, [values?.scores[idx]]);

  const WINNER_OPTIONS = React.useMemo(
    () => [redCornerAthlete, blueCornerAthlete].filter(Boolean),
    [redCornerAthlete, blueCornerAthlete],
  );
  return (
    <Accordion
      key={scr?.id || scr?.roundHeatId}
      expanded={!!accordion[scr?.id || scr?.roundHeatId]}
      onChange={() => {
        setAccordion((prevValue: any) => ({
          ...prevValue,
          [scr?.id || scr?.roundHeatId]: !prevValue[scr?.id || scr?.roundHeatId],
        }));
      }}
    >
      <FightSummary
        scr={scr}
        idx={idx}
        accordion={accordion}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
        matchSummaryDateFormatter={matchSummaryDateFormatter}
      />
      <FightDetails
        scr={scr}
        idx={idx}
        setFieldValue={setFieldValue}
        values={values}
        errors={errors}
        ATHLETE_OPTIONS={ATHLETE_OPTIONS}
        WEIGHT_CLASSES={WEIGHT_CLASSES}
        JUDGE_OPTIONS={JUDGE_OPTIONS}
        REFEREE_OPTIONS={REFEREE_OPTIONS}
        WINNER_OPTIONS={WINNER_OPTIONS}
        WIN_BY_OPTIONS={WIN_BY_OPTIONS}
        FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
        handleBlur={handleBlur}
        handleSaveOrEndFight={handleSaveOrEndFight}
        redCornerAthlete={redCornerAthlete}
        blueCornerAthlete={blueCornerAthlete}
        toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
        toggleCustomStartFightDialog={toggleCustomStartFightDialog}
        toggleCustomEndFightDialog={toggleCustomEndFightDialog}
      />
    </Accordion>
  );
};

const WinnerIcon = () => (
  <Box
    component="img"
    alt="winner icon"
    src={Winnerenabledicon}
    sx={{ ...headToHeadTableStyles.winnerIconsStyle }}
  />
);

const FightSummary = ({
  scr,
  accordion,
  redCornerAthlete,
  blueCornerAthlete,
  matchSummaryDateFormatter,
}: any) => {
  const isAccordionExpanded = accordion[scr?.id || scr?.roundHeatId];

  const blueCorner = blueCornerAthlete && (
    <Box
      sx={{
        width: '100%',
        gridArea: 'blueCornerAthlete',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns:
          blueCornerAthlete?.id === scr?.winnerAthleteId ? '10fr 80fr 10fr' : '90fr 10fr',
        gap: '0.3rem',
      }}
    >
      {blueCornerAthlete?.id === scr?.winnerAthleteId ? <WinnerIcon /> : <></>}
      <AccordionSummaryText sx={{ textAlign: 'end' }}>
        {blueCornerAthlete?.label || '--'}
      </AccordionSummaryText>
      <Box sx={{ display: 'inline-flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'inline-flex',
            borderRadius: '50%',
            height: '0.5rem',
            width: '0.5rem',
            backgroundColor: BLUE_CORNER_COLOR,
          }}
        />
      </Box>
    </Box>
  );

  const redCorner = redCornerAthlete && (
    <Box
      sx={{
        width: '100%',
        gridArea: 'redCornerAthlete',
        justifySelf: 'end',
        display: 'grid',
        alignItems: 'center',
        gridTemplateColumns:
          redCornerAthlete?.id === scr?.winnerAthleteId ? '10fr 80fr 10fr' : '10fr 90fr',
        gap: '0.3rem',
      }}
    >
      <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'inline-flex',
            borderRadius: '50%',
            height: '0.5rem',
            width: '0.5rem',
            backgroundColor: RED_CORNER_COLOR,
          }}
        />
      </Box>
      <AccordionSummaryText>{redCornerAthlete?.label || '--'}</AccordionSummaryText>
      {redCornerAthlete?.id === scr?.winnerAthleteId ? <WinnerIcon /> : <></>}
    </Box>
  );

  return (
    <AccordionSummary expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.2rem' }} />} sx={{ p: 2 }}>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateAreas: `"name playerAndScrDtls matchDtls"`,
          gridTemplateColumns: isAccordionExpanded ? '30fr 20fr 50fr' : '10fr 40fr 50fr',
          gap: '1rem',
        }}
      >
        <Box sx={{ gridArea: 'name', alignSelf: 'center' }}>
          {isAccordionExpanded ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.3rem' }}>
              <AccordionSummaryTextLg>{`${
                scr?.name && scr?.name.toString().toLowerCase().includes('fight')
                  ? `${scr?.name}`
                  : `Fight ${getNumberFromCharacters(scr?.name || '') || 0}`
              }`}</AccordionSummaryTextLg>{' '}
              {processMatchStatusBadges(scr?.heatStatus)}
            </Box>
          ) : (
            <AccordionSummaryText>{`${
              scr?.name && scr?.name.toString().toLowerCase().includes('fight')
                ? `${scr?.name}`
                : `Fight ${getNumberFromCharacters(scr?.name || '') || 0}`
            }`}</AccordionSummaryText>
          )}
        </Box>

        {!isAccordionExpanded && (blueCornerAthlete || redCornerAthlete) && (
          <Box
            sx={{
              gridArea: 'playerAndScrDtls',
              display: 'grid',
              gridTemplateAreas: `"redCornerAthlete score blueCornerAthlete"`,
              alignItems: 'center',
              gridTemplateColumns: 'repeat(3, minmax(0, 33fr))',
            }}
          >
            {redCorner}
            {blueCorner && redCornerAthlete && (
              <Box
                sx={{
                  gridArea: 'score',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AccordionSummaryText
                  sx={{
                    backgroundColor:
                      scr?.winner && scr?.winBy
                        ? scr?.winner?.id === blueCornerAthlete?.id
                          ? `${BLUE_CORNER_WIN_BY}16`
                          : `${RED_CORNER_WIN_BY}16`
                        : '#6C757D29',
                    p: 0.7,
                    fontWeight: 500,
                    borderRadius: '0.45rem',
                    color:
                      scr?.winner && scr?.winBy
                        ? scr?.winner?.id === blueCornerAthlete?.id
                          ? BLUE_CORNER_TEXT_WIN_BY
                          : RED_CORNER_TEXT_WIN_BY
                        : 'inherit',
                  }}
                >
                  {scr?.winBy
                    ? WIN_BY_OPTIONS.find((winOpt) => winOpt.value === scr?.winBy)?.label || '-'
                    : 'vs'}
                </AccordionSummaryText>
              </Box>
            )}
            {blueCorner}
          </Box>
        )}

        <Box
          sx={{
            gridArea: 'matchDtls',
            display: 'grid',
            gridTemplateAreas: `"wgtClass matchTime matchStatus"`,
            alignItems: 'center',
            gridTemplateColumns: 'repeat(3, 33%)',
          }}
        >
          {!isAccordionExpanded && scr?.weightClass && (
            <Box
              sx={{
                gridArea: 'wgtClass',
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
              }}
            >
              <AccordionSummaryText>
                Weight Class:&nbsp;
                <strong>{scr?.weightClass || '--'}</strong>
              </AccordionSummaryText>
            </Box>
          )}

          <Box
            sx={{
              gridArea: 'matchTime',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AccordionSummaryText>
              {`${scr?.endDate ? 'End' : 'Start'}`} Time:&nbsp;
              <strong>
                {matchSummaryDateFormatter(scr?.endDate ? scr?.endDate : scr?.startDate) || '--:--'}
              </strong>
            </AccordionSummaryText>
          </Box>

          {!isAccordionExpanded && (
            <Box
              sx={{
                gridArea: 'matchStatus',
                justifySelf: 'end',
                display: 'flex',
                alignItems: 'center',
                gap: '0.2rem',
              }}
            >
              {processMatchStatusBadges(scr?.heatStatus)}
            </Box>
          )}
        </Box>
      </Box>
    </AccordionSummary>
  );
};

const FightDetails = ({
  scr,
  idx,
  setFieldValue,
  values,
  errors,
  ATHLETE_OPTIONS,
  WEIGHT_CLASSES,
  JUDGE_OPTIONS,
  REFEREE_OPTIONS,
  WINNER_OPTIONS,
  WIN_BY_OPTIONS,
  FIGHT_STATUS_OPTIONS,
  handleSaveOrEndFight,
  redCornerAthlete,
  blueCornerAthlete,
  toggleConfirmDeleteDialog,
  handleConfirmDelete,
  toggleCustomStartFightDialog,
  toggleCustomEndFightDialog,
}: any) => {
  return (
    <AccordionDetails>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"name blank"`,
            gridTemplateColumns: '40fr 60fr',
            mb: 2,
            gap: '1rem',
          }}
        >
          <Box sx={{ gridArea: 'name' }}>
            <AccordionDtlsLabelText mb={1}>Fight No</AccordionDtlsLabelText>
            <ModDecimalField
              name={`scores[${idx}].name`}
              value={values?.scores[idx]?.name}
              fullWidth
              // onBlur={handleBlur}
              onBlur={({ target }) => {
                setFieldValue(`scores[${idx}].name`, target.value);
              }}
              decimalScale={0}
            />
          </Box>
        </Box>
        <Box
          sx={{
            mb: 2,
            display: 'grid',
            gridTemplateAreas: `"startDate endDate heatStatus"`,
            gridTemplateColumns: 'repeat(2, 40fr) 20fr',
            gap: '1rem',
          }}
        >
          <Box sx={{ gridArea: 'startDate' }}>
            <AccordionDtlsLabelText mb={1}>Start Time</AccordionDtlsLabelText>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
              <DateTimePicker
                ampm={false}
                value={values.scores[idx].startDate || null}
                onChange={(newValue) => {
                  setFieldValue(`scores[${idx}].startDate`, newValue);
                }}
                // onClose={() => {
                //   if (
                //     values.scores[idx].heatStatus === FightStatusValues[FightStatusNames.UPCOMING]
                //   ) {
                //     setFieldValue(
                //       `scores[${idx}].heatStatus`,
                //       FightStatusValues[FightStatusNames.LIVE],
                //     );
                //   }
                // }}
                // disabled
                slotProps={{
                  textField: {
                    color: 'secondary',
                    fullWidth: true,
                    placeholder: 'Start Time',
                    sx: {
                      fontWeight: '400',
                      '& .MuiInputBase-input': {
                        padding: '0.6rem 1.5rem',
                        fontSize: '0.812rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          borderLeft: '1px solid #C4C4C4',
                          borderTop: '1px solid #C4C4C4',
                          borderBottom: '1px solid #C4C4C4',
                          borderRadius: 0,
                          borderTopLeftRadius: '0.25rem',
                          borderBottomLeftRadius: '0.25rem',
                          '&:hover': {
                            borderColor: '#000000',
                          },
                        },
                      },
                    },
                    // onBlur: () => {
                    //   if (
                    //     values.scores[idx].heatStatus ===
                    //     FightStatusValues[FightStatusNames.UPCOMING]
                    //   ) {
                    //     setFieldValue(
                    //       `scores[${idx}].heatStatus`,
                    //       FightStatusValues[FightStatusNames.LIVE],
                    //     );
                    //   }
                    // },
                  },
                }}
              />
              <Button
                variant="contained"
                disableElevation
                // disabled={!scr.startDate || !scr.winner}
                onClick={() => {
                  toggleCustomStartFightDialog(scr);
                  // setFieldValue(`scores[${idx}].startDate`, new Date());
                  // setFieldValue(`scores[${idx}].heatStatus`, EventStatusValues[EventStatusNames.LIVE]);
                  // handleSaveOrEndFight(
                  //   {
                  //     ...scr,
                  //     startDate: new Date(),
                  //     heatStatus: FightStatusValues[FightStatusNames.LIVE],
                  //   },
                  //   idx,
                  // );
                }}
                sx={{
                  borderRadius: 0,
                  borderTopRightRadius: '0.25rem',
                  borderBottomRightRadius: '0.25rem',
                }}
              >
                Start Now
              </Button>
            </Box>
          </Box>
          <Box sx={{ gridArea: 'endDate' }}>
            <AccordionDtlsLabelText mb={1}>End Time</AccordionDtlsLabelText>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', alignItems: 'center' }}>
              <DateTimePicker
                ampm={false}
                value={values.scores[idx].endDate || null}
                // onChange={(newValue) => {
                //   setFieldValue(`scores[${idx}].endDate`, newValue);
                // }}
                onClose={() => {
                  if (
                    values.scores[idx].heatStatus !== FightStatusValues[FightStatusNames.COMPLETED]
                  ) {
                    setFieldValue(
                      `scores[${idx}].heatStatus`,
                      FightStatusValues[FightStatusNames.COMPLETED],
                    );
                  }
                }}
                disabled
                slotProps={{
                  textField: {
                    color: 'secondary',
                    fullWidth: true,
                    placeholder: 'End Time',
                    sx: {
                      fontWeight: '400',
                      '& .MuiInputBase-input': {
                        padding: '0.6rem 1.5rem',
                        fontSize: '0.812rem',
                      },
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          border: 'none',
                          borderLeft: '1px solid #C4C4C4',
                          borderTop: '1px solid #C4C4C4',
                          borderBottom: '1px solid #C4C4C4',
                          borderRadius: 0,
                          borderTopLeftRadius: '0.25rem',
                          borderBottomLeftRadius: '0.25rem',
                          '&:hover': {
                            borderColor: '#000000',
                          },
                        },
                      },
                    },
                    onBlur: () => {
                      if (
                        values.scores[idx].heatStatus !==
                        FightStatusValues[FightStatusNames.COMPLETED]
                      ) {
                        setFieldValue(
                          `scores[${idx}].heatStatus`,
                          FightStatusValues[FightStatusNames.COMPLETED],
                        );
                      }
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                disableElevation
                // disabled={!scr.startDate || !scr.winner}
                onClick={() => {
                  toggleCustomEndFightDialog(scr);
                  // setFieldValue(`scores[${idx}].startDate`, new Date());
                  // setFieldValue(`scores[${idx}].heatStatus`, EventStatusValues[EventStatusNames.LIVE]);
                  // handleSaveOrEndFight(
                  //   {
                  //     ...scr,
                  //     startDate: new Date(),
                  //     heatStatus: FightStatusValues[FightStatusNames.LIVE],
                  //   },
                  //   idx,
                  // );
                }}
                sx={{
                  borderRadius: 0,
                  borderTopRightRadius: '0.25rem',
                  borderBottomRightRadius: '0.25rem',
                }}
              >
                End Now
              </Button>
            </Box>
          </Box>
          <Box sx={{ gridArea: 'heatStatus' }}>
            <AccordionDtlsLabelText mb={1}>Fight Status</AccordionDtlsLabelText>
            <ModSelect
              // name={`scores[${idx}].weightClass`}
              value={scr?.heatStatus}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              fullWidth
              onChange={({ target }) => {
                setFieldValue(`scores[${idx}].heatStatus`, +target?.value as unknown as number);
              }}
            >
              {React.Children.toArray(
                FIGHT_STATUS_OPTIONS.map((status: any) => (
                  <MenuItem
                    value={status[0]}
                    disabled={
                      values.scores[idx].startDate &&
                      +status[0] === FightStatusValues[FightStatusNames.UPCOMING]
                        ? true
                        : values.scores[idx].endDate &&
                          (+status[0] === FightStatusValues[FightStatusNames.LIVE] ||
                            +status[0] === FightStatusValues[FightStatusNames.UPCOMING])
                        ? true
                        : false
                    }
                  >
                    {status[1]}
                  </MenuItem>
                )),
              )}
            </ModSelect>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"wgtClass redCornerAthlete blueCornerAthlete scheduledRounds"`,
            gridTemplateColumns: '10fr 40fr 40fr 10fr',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Box sx={{ gridArea: 'wgtClass' }}>
            <AccordionDtlsLabelText mb={1}>Weight Class</AccordionDtlsLabelText>
            <ModSelect
              // name={`scores[${idx}].weightClass`}
              value={scr?.weightClass}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              fullWidth
              onChange={({ target }) => {
                setFieldValue(`scores[${idx}].weightClass`, target?.value);
              }}
            >
              {React.Children.toArray(
                WEIGHT_CLASSES.map((wgt: any) => (
                  <MenuItem value={wgt?.value}>{wgt?.label}</MenuItem>
                )),
              )}
            </ModSelect>
          </Box>
          <Box sx={{ gridArea: 'redCornerAthlete' }}>
            <FormControl error={errors?.scores?.[idx]?.redCornerAthlete ? true : false} fullWidth>
              <AccordionDtlsLabelText mb={1}>Red Corner</AccordionDtlsLabelText>
              <CustomAthleteSelect
                value={values?.scores[idx]?.redCornerAthlete}
                athleteColor={RED_CORNER_COLOR}
                handleBlur={(val) => {
                  const currentAthlete = val;
                  const prevAthlete = values?.scores[idx]?.redCornerAthlete;
                  const currentWinner = values?.scores[idx]?.winner;
                  const isPrevAthleteWinner =
                    currentWinner && prevAthlete?.id === currentWinner?.id;

                  setFieldValue(`scores[${idx}].redCornerAthlete`, currentAthlete);

                  if (isPrevAthleteWinner) {
                    setFieldValue(`scores[${idx}].winner`, currentAthlete);
                  }
                }}
                ATHLETE_OPTIONS={ATHLETE_OPTIONS}
                skipAthlete={values?.scores[idx]?.blueCornerAthlete}
              />
              {errors?.scores?.[idx]?.redCornerAthlete && (
                <FormHelperText>{errors?.scores?.[idx]?.redCornerAthlete}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box sx={{ gridArea: 'blueCornerAthlete' }}>
            <FormControl error={errors?.scores?.[idx]?.blueCornerAthlete ? true : false} fullWidth>
              <AccordionDtlsLabelText mb={1}>Blue Corner</AccordionDtlsLabelText>
              <CustomAthleteSelect
                value={values?.scores[idx]?.blueCornerAthlete}
                athleteColor={BLUE_CORNER_COLOR}
                handleBlur={(val) => {
                  const currentAthlete = val;
                  const prevAthlete = values?.scores[idx]?.blueCornerAthlete;
                  const currentWinner = values?.scores[idx]?.winner;
                  const isPrevAthleteWinner =
                    currentWinner && prevAthlete?.id === currentWinner?.id;

                  setFieldValue(`scores[${idx}].blueCornerAthlete`, currentAthlete);

                  if (isPrevAthleteWinner) {
                    setFieldValue(`scores[${idx}].winner`, currentAthlete);
                  }
                }}
                ATHLETE_OPTIONS={ATHLETE_OPTIONS}
                skipAthlete={values?.scores[idx]?.redCornerAthlete}
              />
              {errors?.scores?.[idx]?.blueCornerAthlete && (
                <FormHelperText>{errors?.scores?.[idx]?.blueCornerAthlete}</FormHelperText>
              )}
            </FormControl>
          </Box>
          <Box
            sx={{
              gridArea: 'scheduledRounds',
              '& .counter-btn': {
                minWidth: '98px',
              },
            }}
          >
            <AccordionDtlsLabelText mb={1}>Scheduled Rounds</AccordionDtlsLabelText>
            <CounterButton
              value={scr?.scheduledRounds || 0}
              changeHandler={(val: number) => {
                setFieldValue(`scores[${idx}].scheduledRounds`, val);
              }}
              bgColor="#F8F9FA"
            />
          </Box>
        </Box>

        <Typography
          sx={{
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            fontWeight: 500,
            mt: 2,
            mb: 1,
            width: '100%',
          }}
        >
          End of the fight
        </Typography>
        <AccordionDtlsLabelText width="100%" mb={1}>
          Referee
        </AccordionDtlsLabelText>

        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"refereeBlock"`,
            gridTemplateColumns: 'minmax(0, 33fr) minmax(0, 67fr)',
            alignItems: 'center',
            gap: '0.6rem',
            mb: 2,
          }}
        >
          <Box sx={{ gridArea: 'refereeBlock' }}>
            <ModAutocomplete
              value={scr?.referee}
              onChange={(_, newValue) => {
                if (typeof newValue === 'string') {
                  setFieldValue(`scores[${idx}].referee`, {
                    label: newValue,
                  });
                } else if (newValue && newValue?.inputValue) {
                  // Create a new value from the user input
                  setFieldValue(`scores[${idx}].referee`, {
                    label: newValue?.inputValue || '',
                    inputValue: newValue?.inputValue || '',
                  });
                } else {
                  setFieldValue(`scores[${idx}].referee`, newValue);
                }
              }}
              options={REFEREE_OPTIONS}
            />
          </Box>
        </Box>
        <AccordionDtlsLabelText width="100%" mb={1}>
          Scores
        </AccordionDtlsLabelText>
        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"judge1 judge2 judge3"`,
            gridTemplateColumns: 'repeat(3, 33fr)',
            alignItems: 'center',
            gap: '0.6rem',
            mb: 2,
          }}
        >
          <Box sx={{ gridArea: 'judge1' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }} mb={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                <Box
                  component="img"
                  src={JudgeIcon}
                  alt="Judge Icon"
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    display: 'grid',
                    gridTemplateColumns: '80fr 20fr',
                    gap: '0.5rem',
                  }}
                />{' '}
                <AccordionDtlsLabelText>Judge #1</AccordionDtlsLabelText>
              </Box>
              <ScoreLabels />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }}>
              <ModAutocomplete
                value={scr?.judge1?.name}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    setFieldValue(`scores[${idx}].judge1.name`, {
                      label: newValue,
                    });
                  } else if (newValue && newValue?.inputValue) {
                    // Create a new value from the user input
                    setFieldValue(`scores[${idx}].judge1.name`, {
                      label: newValue?.inputValue || '',
                      inputValue: newValue?.inputValue || '',
                    });
                  } else {
                    setFieldValue(`scores[${idx}].judge1.name`, newValue);
                  }
                }}
                options={JUDGE_OPTIONS}
              />
              <JudgeScores
                score1={scr?.judge1?.score1}
                score2={scr?.judge1?.score2}
                handleChangeScr1={({ target: { value } }: number) =>
                  setFieldValue(`scores[${idx}].judge1.score1`, value)
                }
                handleChangeScr2={({ target: { value } }: number) =>
                  setFieldValue(`scores[${idx}].judge1.score2`, value)
                }
              />
            </Box>
          </Box>

          <Box sx={{ gridArea: 'judge2' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }} mb={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                <Box
                  component="img"
                  src={JudgeIcon}
                  alt="Judge Icon"
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    display: 'grid',
                    gridTemplateColumns: '80fr 20fr',
                    gap: '0.5rem',
                  }}
                />{' '}
                <AccordionDtlsLabelText>Judge #2</AccordionDtlsLabelText>
              </Box>
              <ScoreLabels />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }}>
              <ModAutocomplete
                value={scr?.judge2?.name}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    setFieldValue(`scores[${idx}].judge2.name`, {
                      label: newValue,
                    });
                  } else if (newValue && newValue?.inputValue) {
                    // Create a new value from the user input
                    setFieldValue(`scores[${idx}].judge2.name`, {
                      label: newValue?.inputValue || '',
                      inputValue: newValue?.inputValue || '',
                    });
                  } else {
                    setFieldValue(`scores[${idx}].judge2.name`, newValue);
                  }
                }}
                options={JUDGE_OPTIONS}
              />
              <JudgeScores
                score1={scr?.judge2?.score1}
                score2={scr?.judge2?.score2}
                handleChangeScr1={({ target: { value } }: number) =>
                  setFieldValue(`scores[${idx}].judge2.score1`, value)
                }
                handleChangeScr2={({ target: { value } }: number) =>
                  setFieldValue(`scores[${idx}].judge2.score2`, value)
                }
              />
            </Box>
          </Box>
          <Box sx={{ gridArea: 'judge3' }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }} mb={1}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.4rem' }}>
                <Box
                  component="img"
                  src={JudgeIcon}
                  alt="Judge Icon"
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    display: 'grid',
                    gridTemplateColumns: '80fr 20fr',
                    gap: '0.5rem',
                  }}
                />{' '}
                <AccordionDtlsLabelText>Judge #3</AccordionDtlsLabelText>
              </Box>
              <ScoreLabels />
            </Box>

            <Box sx={{ display: 'grid', gridTemplateColumns: '80fr 20fr', gap: '0.5rem' }}>
              <ModAutocomplete
                value={scr?.judge3?.name}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    setFieldValue(`scores[${idx}].judge3.name`, {
                      label: newValue,
                    });
                  } else if (newValue && newValue?.inputValue) {
                    // Create a new value from the user input
                    setFieldValue(`scores[${idx}].judge3.name`, {
                      label: newValue?.inputValue || '',
                      inputValue: newValue?.inputValue || '',
                    });
                  } else {
                    setFieldValue(`scores[${idx}].judge3.name`, newValue);
                  }
                }}
                options={JUDGE_OPTIONS}
              />
              <JudgeScores
                score1={scr?.judge3?.score1}
                score2={scr?.judge3?.score2}
                handleChangeScr1={({ target: { value } }: number) =>
                  setFieldValue(`scores[${idx}].judge3.score1`, value)
                }
                handleChangeScr2={({ target: { value } }: number) =>
                  setFieldValue(`scores[${idx}].judge3.score2`, value)
                }
              />
            </Box>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateAreas: `"winner lastRound blank"`,
            gridTemplateColumns: '33fr 10fr 57fr',
            gap: '1rem',
          }}
        >
          <Box sx={{ gridArea: 'winner' }}>
            <AccordionDtlsLabelText width="100%" mb={1}>
              Winner
            </AccordionDtlsLabelText>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '50fr 4ch 40fr ',
                alignItems: 'center',
                mb: 2,
              }}
            >
              <ModSelect
                value={scr?.winner}
                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                fullWidth
                // disabled={
                //   ![
                //     EventStatusValues[EventStatusNames.LIVE],
                //     EventStatusValues[EventStatusNames.COMPLETED],
                //   ].includes(scr?.heatStatus)
                // }
                onChange={({ target }) => {
                  setFieldValue(`scores[${idx}].winner`, target.value);
                  setFieldValue(`scores[${idx}].winnerAthleteId`, target?.value?.id || null);
                }}
                sx={{ backgroundColor: '#ffffff' }}
                renderValue={(selected) => {
                  const selectedAthlete = ATHLETE_OPTIONS.find(
                    (athlete: any) => athlete === selected,
                  );
                  let colorIcon = null;

                  if (redCornerAthlete === selected) {
                    colorIcon = (
                      <Box
                        sx={{
                          display: 'inline-flex',
                          borderRadius: '50%',
                          height: '0.5rem',
                          width: '0.5rem',
                          backgroundColor: RED_CORNER_COLOR,
                        }}
                      ></Box>
                    );
                  }

                  if (blueCornerAthlete === selected) {
                    colorIcon = (
                      <Box
                        sx={{
                          display: 'inline-flex',
                          borderRadius: '50%',
                          height: '0.5rem',
                          width: '0.5rem',
                          backgroundColor: BLUE_CORNER_COLOR,
                        }}
                      ></Box>
                    );
                  }
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.4rem',
                      }}
                    >
                      {colorIcon && colorIcon}
                      <AccordionSummaryText>
                        {selectedAthlete?.label || 'Please select winner'}
                      </AccordionSummaryText>
                    </Box>
                  );
                }}
              >
                {React.Children.toArray(
                  WINNER_OPTIONS.map((athlete: any) => (
                    <MenuItem value={athlete}>{athlete?.label}</MenuItem>
                  )),
                )}
              </ModSelect>
              <AccordionDtlsLabelText sx={{ textAlign: 'center' }}>by</AccordionDtlsLabelText>
              <ModSelect
                value={scr?.winBy}
                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                fullWidth
                sx={{ backgroundColor: '#ffffff' }}
                onChange={({ target }) => {
                  setFieldValue(`scores[${idx}].winBy`, target.value);
                }}
                // disabled={
                //   ![
                //     EventStatusValues[EventStatusNames.LIVE],
                //     EventStatusValues[EventStatusNames.COMPLETED],
                //   ].includes(scr?.heatStatus)
                // }
              >
                {React.Children.toArray(
                  WIN_BY_OPTIONS.map((dec: any) => (
                    <MenuItem value={dec?.value}>{dec?.label}</MenuItem>
                  )),
                )}
              </ModSelect>
            </Box>
          </Box>
          <Box
            sx={{
              gridArea: 'lastRound',
              '& .counter-btn': {
                minWidth: '98px',
              },
            }}
          >
            <AccordionDtlsLabelText mb={1}>Fight Round Ended</AccordionDtlsLabelText>
            <CounterButton
              value={scr?.lastRound || 0}
              changeHandler={(val: number) => {
                setFieldValue(`scores[${idx}].lastRound`, val);
              }}
              bgColor="#F8F9FA"
            />
          </Box>
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '35fr 65fr',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Box>
            <AccordionDtlsLabelText mb={1}>Notes</AccordionDtlsLabelText>
            <CustomMultilineInput
              value={scr?.notes || ''}
              handleBlur={({ target }: any) => {
                setFieldValue(`scores[${idx}].notes`, target.value);
              }}
            />
          </Box>
        </Box>
        <Grid container justifyContent="flex-end" alignItems="center" gap="0.5rem">
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            // disabled={scr.winner}
            onClick={() => handleSaveOrEndFight(scr, idx)}
          >
            Save
          </Button>

          <Button
            variant="contained"
            disableElevation
            // disabled={!scr.startDate || !scr.winner}
            onClick={() => {
              toggleCustomEndFightDialog(scr);
            }}
          >
            End this fight
          </Button>
          <Button
            variant="contained"
            disableElevation
            color="error"
            // disabled={scr.winner}
            onClick={() => {
              if (!scr?.roundHeatId) {
                handleConfirmDelete(scr);
              } else {
                toggleConfirmDeleteDialog(scr);
              }
            }}
            startIcon={<DeleteIcon />}
          >
            Delete
          </Button>
        </Grid>
      </Box>
    </AccordionDetails>
  );
};

export default function BYBEventScores() {
  const [showDeleteFightDialog, setShowDeleteFightDialog] = React.useState(false);
  const [fightData, setFightData] = React.useState(undefined);
  const [showStartFightDialog, setShowStartFightDialog] = React.useState(false);
  const [showEndFightDialog, setShowEndFightDialog] = React.useState(false);
  const { eventId = '' } = useParams();
  const queryClient = useQueryClient();
  const { data: eventScores } = useFetchEventScores(SPORT_TYPES.BYB, eventId);
  const { data: eventSeeds } = useFetchFightParticipants({
    sport: SPORT_TYPES.BYB,
    eventId,
    archived: ARCHIVED,
  });
  // const event: any = queryClient.getQueryData([
  //   keys.events.fetchEvent,
  //   SPORT_TYPES.BYB,
  //   eventId,
  //   'Event',
  // ]);

  const { data: weightClasses } = useFetchWeightClasses(SPORT_TYPES.BYB, 'Scores');

  const WEIGHT_CLASSES = React.useMemo(
    () =>
      weightClasses ? weightClasses?.map((wgtClass) => ({ label: wgtClass, value: wgtClass })) : [],
    [weightClasses],
  );

  const { mutateAsync: addBYBScores } = useAddBYBScores();
  const { mutateAsync: updateBYBScores } = useUpdateBYBScores();
  const { mutateAsync: deleteBYBScores } = useDeleteBYBScores();

  const toggleConfirmDeleteDialog = (data: any) => {
    setShowDeleteFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const toggleCustomStartFightDialog = (data: any) => {
    setShowStartFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const toggleCustomEndFightDialog = (data: any) => {
    setShowEndFightDialog((prev) => !prev);
    if (data) {
      setFightData(data);
    }
  };

  const ATHLETE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds
        ?.filter((seed: any) => seed?.athlete)
        ?.map((seed: any) => {
          const { athlete } = seed;
          const fullName = [athlete?.firstName, athlete?.middleName, athlete?.lastName]
            .filter(Boolean)
            .join(' ');
          const nickName = athlete?.nickName ? ` (${athlete.nickName})` : '';
          const weightClass = athlete?.weightClass
            ? ` - ${getFirstLettersCombined(athlete?.weightClass || '')}`
            : '';

          return {
            ...athlete,
            label: `${fullName}${nickName}${weightClass}`,
            value: athlete?.id,
            firstName: athlete?.firstName,
            middleName: athlete?.middleName,
            lastName: athlete?.lastName,
            nickName: athlete?.nickName,
            weightClass: athlete?.weightClass,
          };
        }) || []
    );
  }, [eventSeeds]);

  const FIGHT_STATUS_OPTIONS = React.useMemo(
    () =>
      Object.entries(FightStatusKeys).filter((status) =>
        [
          FightStatusNames.UPCOMING,
          FightStatusNames.LIVE,
          FightStatusNames.COMPLETED,
          FightStatusNames.CANCELED,
          FightStatusNames.MEDICAL_STOPPAGE,
        ].includes(status[1]),
      ),
    [],
  );

  const refreshScores = () => {
    queryClient.invalidateQueries([keys.events.fetchEventScores, SPORT_TYPES.BYB, eventId]);
    queryClient.invalidateQueries([
      keys.fights.fetchFightParticipants,
      SPORT_TYPES.BYB,
      eventId,
      ARCHIVED,
    ]);
    if (fightData) {
      setFightData(undefined);
    }
  };

  const initialScoresFormatter = (fights: any) => {
    if (!fights) return [];

    return fights.map((fight: any) => {
      const blueCornerAthlete = ATHLETE_OPTIONS.find(
        (athlete: any) =>
          athlete.id === (fight.scores.find((scr: any) => scr.typeBlue) || {}).athleteId,
      );
      const redCornerAthlete = ATHLETE_OPTIONS.find(
        (athlete: any) =>
          athlete.id === (fight.scores.find((scr: any) => !scr.typeBlue) || {}).athleteId,
      );
      const winner = ATHLETE_OPTIONS.find((athlete: any) => athlete.id === fight.winnerAthleteId);

      const groupedByJudgeName = groupBy(sortBy(fight?.scores || [], ['judgeNo']), 'judge');
      const groupedByJudgeNameArr = Object.entries(groupedByJudgeName);

      const [judge1 = DEFAULT_JUDGE_OBJ, judge2 = DEFAULT_JUDGE_OBJ, judge3 = DEFAULT_JUDGE_OBJ] =
        groupedByJudgeNameArr.map(([judgeName, scores]) => ({
          name: judgeName ? { label: judgeName } : null,
          score1: scores.find((judgeScr: any) => !judgeScr.typeBlue)?.score || 0,
          score2: scores.find((judgeScr: any) => judgeScr.typeBlue)?.score || 0,
        }));

      return {
        ...fight,
        name: fight?.heatName || '',
        blueCornerAthlete: blueCornerAthlete || null,
        redCornerAthlete: redCornerAthlete || null,
        startDate: fight.startDate ? format(fight.startDate, undefined, true) : null,
        endDate: fight.endDate ? format(fight.endDate, undefined, true) : null,
        winner: winner || null,
        referee: fight?.referee ? { label: fight?.referee } : null,
        judge1,
        judge2,
        judge3,
      };
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      scores: initialScoresFormatter(eventScores || []),
    },
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const { values, errors, setFieldValue, setFieldError, handleBlur } = formik;

  const JUDGE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds
        ?.filter((seed: any) => seed?.judge)
        ?.map((seed: any) => {
          const { judge } = seed;
          const fullName = [judge?.firstName, judge?.middleName, judge?.lastName]
            .filter(Boolean)
            .join(' ');

          return {
            label: `${fullName}`,
          };
        }) || []
    );
  }, [eventSeeds]);

  const REFEREE_OPTIONS = React.useMemo(() => {
    return (
      eventSeeds
        ?.filter((seed: any) => seed?.referee)
        ?.map((seed: any) => {
          const { referee } = seed;
          const fullName = [referee?.firstName, referee?.middleName, referee?.lastName]
            .filter(Boolean)
            .join(' ');

          return {
            label: `${fullName}`,
          };
        }) || []
    );
  }, [eventSeeds]);

  const [accordion, setAccordion] = React.useState<{ [key: string]: boolean }>(
    values?.scores?.reduce(
      (o: any, val: { id: string; heatStatus: number; roundHeatId: string }) => ({
        ...o,
        [val?.id || val?.roundHeatId]:
          FightStatusKeys[val?.heatStatus] === FightStatusNames.LIVE ? true : false,
      }),
      {},
    ),
  );

  React.useEffect(() => {
    if (!values || !values.scores) return;

    setAccordion((prev: { [key: string]: boolean }) => {
      const updatedAccordion: { [key: string]: boolean } = values.scores.reduce(
        (
          acc: { [key: string]: boolean },
          val: { id: string | undefined; roundHeatId: string; heatStatus: number },
        ) => {
          const id: string = val.id || val.roundHeatId;
          if (prev[id] !== undefined) return { ...acc, [id]: prev[id] };
          const heatStatus: string = FightStatusKeys[val.heatStatus];
          return { ...acc, [id]: heatStatus === FightStatusNames.LIVE };
        },
        {},
      );

      return updatedAccordion;
    });
  }, [values?.scores]);

  const payloadFormatter = (data: any) => {
    const scores: any = [];
    [data?.judge1, data?.judge2, data?.judge3].filter(Boolean)?.forEach((judge, idx) => {
      scores.push(
        ...[
          {
            judge: judge?.name?.label || '',
            score: Number(judge?.score2 || null) || 0,
            athleteId: data?.blueCornerAthlete?.id || null,
            typeBlue: true,
            judgeNo: idx + 1,
          },
          {
            judge: judge?.name?.label || '',
            score: Number(judge?.score1 || null) || 0,
            athleteId: data?.redCornerAthlete?.id || null,
            typeBlue: false,
            judgeNo: idx + 1,
          },
        ],
      );
    });

    const payload = {
      startDate: data?.startDate ? zonedTimeToUtc(data?.startDate, DEFAULT_TIMEZONE) : null,
      endDate: data?.endDate ? zonedTimeToUtc(data?.endDate, DEFAULT_TIMEZONE) : null,
      winBy: data?.winBy || null,
      notes: data?.notes || null,
      scheduledRounds: data?.scheduledRounds || 0,
      name: `Fight ${getNumberFromCharacters(data?.name || '')}`,
      lastRound: data?.lastRound || null,
      referee: data?.referee ? data?.referee?.label : null,
      winnerAthleteId: data?.winner ? data?.winner?.id : null,
      weightClass: data?.weightClass || null,
      heatStatus: +data?.heatStatus,
      scores,
    };
    return payload;
  };

  const handleSaveOrEndFight = async (data: any, idx: number) => {
    try {
      await fightValidationSchema.validate(data, { abortEarly: false });
      if (!data?.roundHeatId) {
        addBYBScores(
          { sport: SPORT_TYPES.BYB, eventId, requestPayload: payloadFormatter(data) },
          {
            onSettled: (_, err) => {
              if (!err) {
                enqueueSnackbar('Added fight successfully!');
                refreshScores();
              }
            },
          },
        );
      }
      if (data?.roundHeatId) {
        updateBYBScores(
          {
            sport: SPORT_TYPES.BYB,
            eventId,
            roundHeatId: data?.roundHeatId,
            requestPayload: payloadFormatter(data),
          },
          {
            onSettled: (_, err) => {
              if (!err) {
                enqueueSnackbar('Updated fight successfully!');
                refreshScores();
              }
            },
          },
        );
      }
    } catch (err: any) {
      if (err?.inner) {
        err?.inner?.forEach((error: any) => {
          setFieldError(`scores[${idx}].${error.path}`, error?.message);
        });
      }
    }
  };

  const matchSummaryDateFormatter = (matchTime: Date) => {
    try {
      if (!matchTime || isNaN(matchTime.getTime())) {
        return '';
      }

      const formattedTime = normalFormat(matchTime, 'HH:mm');
      return `${formattedTime} PST`;
    } catch (error) {
      return '';
    }
  };

  const handleConfirmDelete = (data: any) => {
    if (!data?.roundHeatId) {
      const modScores = values.scores.filter((scrData: any) => scrData?.id !== data?.id);
      setFieldValue('scores', modScores);
    }

    if (data?.roundHeatId) {
      deleteBYBScores(
        {
          sport: SPORT_TYPES.BYB,
          eventId,
          roundHeatId: data?.roundHeatId,
        },
        {
          onSettled: (_, err) => {
            if (!err) {
              enqueueSnackbar('Deleted fight successfully!');
              refreshScores();
            }
          },
        },
      );
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} mb={2}>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          disableElevation
          onClick={() => {
            const nextId = (values?.scores?.length || 0) + 1;
            const lastScoreName = values?.scores?.[values?.scores?.length - 1]?.name || '0';
            const nextName = getNumberFromCharacters(lastScoreName) + 1;

            setFieldValue('scores', [
              ...(values?.scores || []),
              {
                ...DEFAULT_FIGHT,
                id: nextId,
                name: nextName,
                startDate: null,
              },
            ]);
          }}
        >
          Add fight
        </Button>
      </Box>
      {React.Children.toArray(
        values?.scores?.map((scr: any, idx: number) => (
          <FightContainer
            scr={scr}
            idx={idx}
            values={values}
            setFieldValue={setFieldValue}
            errors={errors}
            ATHLETE_OPTIONS={ATHLETE_OPTIONS}
            WEIGHT_CLASSES={WEIGHT_CLASSES}
            JUDGE_OPTIONS={JUDGE_OPTIONS}
            REFEREE_OPTIONS={REFEREE_OPTIONS}
            WIN_BY_OPTIONS={WIN_BY_OPTIONS}
            FIGHT_STATUS_OPTIONS={FIGHT_STATUS_OPTIONS}
            handleBlur={handleBlur}
            handleSaveOrEndFight={handleSaveOrEndFight}
            accordion={accordion}
            setAccordion={setAccordion}
            matchSummaryDateFormatter={matchSummaryDateFormatter}
            toggleConfirmDeleteDialog={toggleConfirmDeleteDialog}
            handleConfirmDelete={handleConfirmDelete}
            toggleCustomStartFightDialog={toggleCustomStartFightDialog}
            toggleCustomEndFightDialog={toggleCustomEndFightDialog}
          />
        )),
      )}

      <DeleteConfirmDialog
        open={showDeleteFightDialog}
        handleClose={() => toggleConfirmDeleteDialog(undefined)}
        handleConfirm={() => {
          toggleConfirmDeleteDialog(undefined);
          handleConfirmDelete(fightData);
        }}
        title={`Confirm Deletion`}
        body={
          <Typography sx={{ fontSize: '0.9rem' }}>
            Data will be deleted permanently, to confirm deletion, type <strong>DELETE</strong> in
            the text input field.
          </Typography>
        }
      />

      <CustomEndFightDialog
        open={showStartFightDialog}
        handleClose={() => toggleCustomStartFightDialog(undefined)}
        handleConfirm={(newVal: any) => {
          const idx = values.scores.findIndex(
            (scr: any) =>
              scr?.roundHeatId === fightData?.roundHeatId && scr?.name === fightData?.name,
          );
          toggleCustomStartFightDialog(undefined);
          handleSaveOrEndFight(
            {
              ...(fightData as unknown as any),
              startDate: newVal,
              ...(fightData?.heatStatus === FightStatusValues[FightStatusNames.UPCOMING] && {
                heatStatus: FightStatusValues[FightStatusNames.LIVE],
              }),
            },
            idx,
          );
        }}
        title={'ARE YOU SURE YOU WANT TO START THIS FIGHT?'}
        body={'This will set the Start Time. You can come back to make edits if needed.'}
        isStartTime
      />
      <CustomEndFightDialog
        open={showEndFightDialog}
        handleClose={() => toggleCustomEndFightDialog(undefined)}
        handleConfirm={(newVal: any) => {
          const idx = values.scores.findIndex(
            (scr: any) =>
              scr?.roundHeatId === fightData?.roundHeatId && scr?.name === fightData?.name,
          );
          toggleCustomEndFightDialog(undefined);
          handleSaveOrEndFight(
            {
              ...(fightData as unknown as any),
              endDate: newVal,
              heatStatus: FightStatusValues[FightStatusNames.COMPLETED],
            },
            idx,
          );
        }}
        title={'ARE YOU SURE YOU WANT TO END THIS FIGHT?'}
        body={'This will set the scores and End Time. You can come back to make edits if needed.'}
      />
    </Box>
  );
}
