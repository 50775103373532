const config = {
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  oddsOutputAPIBaseURL: import.meta.env.VITE_ODDS_OUTPUT_API_BASE_URL,
  environment: import.meta.env.VITE_ENVIRONMENT,
  isDevelop: import.meta.env.VITE_ENVIRONMENT === 'develop',
  isStaging: import.meta.env.VITE_ENVIRONMENT === 'staging',
  isProd: import.meta.env.VITE_ENVIRONMENT === 'production',
  host: location.host || '',
  isAnalyticsEnabled: ['production', 'staging'].includes(import.meta.env.VITE_ENVIRONMENT),

  googleRecaptchaKey: import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY,

  firebaseConfig: {
    apiKey: import.meta.env.VITE_API_KEY,
    authDomain: import.meta.env.VITE_AUTH_DOMAIN,
    databaseURL: import.meta.env.VITE_DATABASE_URL,
    projectId: import.meta.env.VITE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_ID,
  },

  hotjarId: import.meta.env.VITE_HOTJAR_ID,
  hotjarSv: import.meta.env.VITE_HOTJAR_SV,
  googleAnalyticsUniversal: import.meta.env.VITE_GA_ANALYTICS_UA,
  microsoftClarityId: import.meta.env.VITE_MICROSOFT_CLARITY_ID,
  logrocketAppId: import.meta.env.VITE_LOGROCKET_APP_ID,

  socketBaseUrl: import.meta.env.VITE_SOCKET_BASE_URL,

  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
};

export default config;
