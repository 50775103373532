import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { hotjar } from 'react-hotjar';
import ReactGA from 'react-ga';
import { clarity } from 'react-microsoft-clarity';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import RedirectWrapper from './components/RedirectWrapper';

// Route Blocking feature
// import { UnsavedChangesWarning } from './components/UnsavedChangesWarning';

import Router from './Router';
import { queryClient } from './react-query/queryClient';
import { theme } from './constants/theme';
import { SnackbarUtilsConfigurator } from './utils/SnackbarUtils';

import config from '@/config/vars';
import initializeLogRocket from './logrocket.config';

const sentryArgs = {
  dsn: config.sentryDSN,
  integrations: [
    // Sentry.browserTracingIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  env: config.environment,
  enabled: config.isProd || config.isStaging,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  initialScope: (scope: any) => {
    scope.setTag('environment', config.environment);
    return scope;
  },
};

function App() {
  React.useEffect(() => {
    if (config.isAnalyticsEnabled) {
      initializeLogRocket();
      hotjar.initialize({ id: config.hotjarId, sv: config.hotjarSv });
      ReactGA.initialize(config.googleAnalyticsUniversal);
      clarity.init(config.microsoftClarityId);
    }

    if (config.isProd || config.isStaging) Sentry.init(sentryArgs);
  }, []);

  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              autoHideDuration={3000}
              maxSnack={1}
            >
              <SnackbarUtilsConfigurator />

              <ThemeProvider theme={theme}>
                {/* <UnsavedChangesWarning /> */}
                <CssBaseline />
                <RedirectWrapper>
                  <Router />
                </RedirectWrapper>
              </ThemeProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </RecoilRoot>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
