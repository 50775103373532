import React, { useContext } from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import Grid from '@mui/material/Grid';
import { Box, Button } from '@mui/material';

import useFetchGameOdds from '@/hooks/games/useFetchGameOdds';
import usePublishOdds from '@/hooks/odds/publishOdds/usePublishOdds';

import { oddsType } from '@/atoms/oddsType';
import Markets from './components/Markets';
import GameScores from './components/Scores';
import Slider from '@/components/Slider';
import MatchCardWithSlider from './components/MatchCardWithSlider';
import OddTypeSwitcher from '@/features/odds/OddTypeSwitcher';

import SPORT_TYPES from '@/constants/sportTypes';
import {
  externalOddModifiersForAllMarkets,
  formatMarketData,
  impossibleCombos,
  marketOddsDownloadPayload,
  marketOddsPayload,
  mergeOdds,
} from './components/Markets/marketHelper';
import PublishOdds from '@/components/PublishOdds';
import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';

import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import { GameDTO, GameEvent, Summary } from '@/types/game';

import { JaiAlaiStatusNames, JaiAlaiStatusValues } from '@/constants/jaialai';

import ExportBtn from '@/components/ExportBtn';
import { gameExcelDownloader } from '@/helpers/gameExcelDownloader';

import { handleAuthError, sendEvent, subscribeToAllEvents } from '@/utils/socketService';
import { WEB_SOCKET_EMIT_EVENT_TYPES, WEB_SOCKET_EVENT_TYPES } from '@/constants/socket';
import { JAI_ALAI_GAME_QUERY_PARAMS } from '@/constants/misc';
import DebugBtn from './components/Markets/DebugBtn';
import { EventStatusNames, EventStatusValues } from '@/constants/events';
// import { FIELD_TO_DEBUG } from './components/Markets/constants';

const MAX_HOLD_PERCENTAGE = 200;
export const JAI_ALAI_NAMESPACE = SPORT_TYPES.JAIALAI;
export const INITIAL_JAI_ALAI_VALUE = {
  odds: [],
  clientUpdatedAtDate: null,
  traderUpdatedAtDate: null,
};

export default function JAIALAIGameScores({
  game,
  event, // isFetchingGame,
}: {
  game: GameDTO;
  isFetchingGame?: boolean;
  event: any;
}) {
  const { gameDateId = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: publishOdds } = usePublishOdds();
  const [oddTypeSelected] = useRecoilState(oddsType);

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const matchSelected = queryParams.get(JAI_ALAI_GAME_QUERY_PARAMS.matchSelected);

  const [events, setEvents] = React.useState<GameEvent[]>([]);
  const [summary, setSummary] = React.useState<Summary | any>({});

  React.useEffect(() => {
    if (game) {
      setEvents(game?.events || []);
      setSummary(game?.summary || {});
    }
  }, [game]);

  const {
    selectedGame,
    setSelectedGame,
    isDisabledMatch,
    setIsDisabledMatch,
    handleDisableMatch,
    handleEnableMatch,
    setIsMatchOutcomeImpossible,
  } = useContext(JAIALAIGameContext);

  React.useEffect(() => {
    // Set selected game if matchSelected is available
    if (matchSelected) {
      setSelectedGame(matchSelected);
      return;
    }

    // Reset selected Game
    setSelectedGame('');

    const gameEvents = game?.events || [];
    const gameSummary = game?.summary || {};
    if (game?.events?.length === 0) return; // Early exit if events is not defined

    const redirectParams = new URLSearchParams();
    const defaultMatchId = gameSummary?.defaultMatchId || '';

    // Set the default match ID in the URL params
    redirectParams.set(JAI_ALAI_GAME_QUERY_PARAMS.matchSelected, defaultMatchId);

    // Handle event selection logic
    const selectedGameId =
      gameEvents.length > 0
        ? gameEvents.find((event) => event.eventStatus === JaiAlaiStatusValues.LIVE)?.id ||
          gameEvents.find((event) => event.eventStatus === JaiAlaiStatusValues.UPCOMING)?.id ||
          gameEvents[0]?.id ||
          defaultMatchId
        : defaultMatchId;

    // Update the match selected parameter in the URL
    redirectParams.set(JAI_ALAI_GAME_QUERY_PARAMS.matchSelected, selectedGameId);

    // Navigate to the updated URL
    navigate(
      {
        pathname: location.pathname,
        search: redirectParams.toString(),
      },
      { replace: true },
    );
  }, [game, matchSelected]);

  const modMatches: any = React.useMemo(
    () =>
      events && events.length > 0
        ? events.map((evt: any) => {
            const match = summary?.matches?.find(
              (currentMatch: any) => currentMatch?.providerMatchId === evt?.providerMatchId,
            );

            return {
              ...evt,
              ...(match
                ? {
                    startDate: match?.startDate ? match?.startDate : event?.startDate,
                    endDate: match?.endDate,
                  }
                : {}),
            };
          })
        : [],
    [events, summary?.matches],
  );

  const selectedMatch: any = React.useMemo(() => {
    if (!modMatches || modMatches?.length === 0 || !selectedGame) return null;
    const currentSelectedMatch = modMatches.find((event: any) => event.id === selectedGame);

    return {
      ...modMatches.find((event: any) => event?.id === selectedGame),
      matchName: `Match ${currentSelectedMatch?.eventNumber || ''}`,
    };
  }, [selectedGame, modMatches]);

  const { prevMatch, nextMatch } = React.useMemo(() => {
    const currentSelectedMatchIndex = events.findIndex((event) => event.id === selectedGame);

    if (currentSelectedMatchIndex === -1) {
      return { prevMatch: null, nextMatch: null };
    }

    const prevMatchIndex = currentSelectedMatchIndex - 1;
    const nextMatchIndex = currentSelectedMatchIndex + 1;

    const prevMatch =
      prevMatchIndex >= 0
        ? {
            ...events[prevMatchIndex],
            matchName: `Match ${events[prevMatchIndex]?.eventNumber || ''}`,
          }
        : null;
    const nextMatch =
      nextMatchIndex < events.length
        ? {
            ...events[nextMatchIndex],
            matchName: `Match ${events[nextMatchIndex]?.eventNumber || ''}`,
          }
        : null;

    return { prevMatch, nextMatch };
  }, [selectedGame, events]);

  const { data } = useFetchGameOdds({
    sportType: SPORT_TYPES.JAIALAI,
    eventId: selectedGame || '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_JAI_ALAI_VALUE,
    onSubmit: (_, { setSubmitting }) => {
      setSubmitting(false);
    },
  });

  const { values, setFieldValue } = formik;

  React.useEffect(() => {
    if (game) {
      setFieldValue('odds', INITIAL_JAI_ALAI_VALUE.odds, false);
      setFieldValue('clientUpdatedAtDate', INITIAL_JAI_ALAI_VALUE.clientUpdatedAtDate, false);
      setFieldValue('traderUpdatedAtDate', INITIAL_JAI_ALAI_VALUE.traderUpdatedAtDate, false);
    }
  }, [game]);

  React.useEffect(() => {
    if (data) {
      // Use this in future update
      /*const newOdds = formatMarketData({
        data: data?.odds || [],
        maxHoldPercentage: MAX_HOLD_PERCENTAGE,
      });*/
      const newOdds = formatMarketData({ data, maxHoldPercentage: MAX_HOLD_PERCENTAGE });
      const formattedOdds =
        values?.odds.length === 0
          ? newOdds
          : mergeOdds({
              newOdds,
              oldOdds: values?.odds,
            });

      setFieldValue('odds', formattedOdds, false);
      setFieldValue('clientUpdatedAtDate', data?.clientUpdatedAtDate || null, false);
      setFieldValue('traderUpdatedAtDate', data?.traderUpdatedAtDate || null, false);
    }
  }, [data]);

  const updateOdds = (payload: { odds?: any }) => {
    const finalPayload = marketOddsPayload({ odds: payload?.odds ? payload?.odds : values.odds });
    if (finalPayload.length > 0) {
      handleDisableMatch();
      sendEvent(
        JAI_ALAI_NAMESPACE,
        WEB_SOCKET_EMIT_EVENT_TYPES.UPDATE_ODDS,
        { eventId: selectedGame, items: finalPayload },
        () => {
          handleEnableMatch();
          enqueueSnackbar('Updated Market Odds successfully!');
        },
      );
    }
  };

  const externalUpdateOdds = ({ odds = [], originalOdds = [] }: any) => {
    const finalPayload = odds;
    if (finalPayload.length > 0) {
      enqueueSnackbar('Updating Market Odds Calc!');
      handleDisableMatch();
      sendEvent(
        JAI_ALAI_NAMESPACE,
        WEB_SOCKET_EMIT_EVENT_TYPES.FETCH_GAME_MARKET_ODDS,
        finalPayload,
        ({ data }) => {
          if (typeof data === 'object') {
            const oddsMod = externalOddModifiersForAllMarkets({
              allMarketOdds: originalOdds,
              externalOdds: data.data || [],
            });
            updateOdds({ odds: oddsMod });
            // !skipNotif && enqueueSnackbar('Updated Market Odds Calc successfully!');
          }
        },
      );
    }
  };

  const handleEventRefresh = (data: any) => {
    if (data.id === gameDateId) {
      setEvents(data?.events);
      setSummary(data?.summary);
    }
  };

  const handleOddsRefresh = (data: any) => {
    if (data.gameDateId === gameDateId && data.eventId === selectedGame) {
      setFieldValue('clientUpdatedAtDate', data?.clientUpdatedAtDate || null, false);
      setFieldValue('traderUpdatedAtDate', data?.traderUpdatedAtDate || null, false);
      const newOdds = formatMarketData({
        data: data || [],
        maxHoldPercentage: MAX_HOLD_PERCENTAGE,
      });
      setFieldValue('odds', newOdds, false);
    }
  };

  const handleValidationError = (data: any) => {
    if (data) {
      data.splice(0, 1).forEach((item: any) => {
        Object.keys(item.constraints).forEach((constraintKey) => {
          const errorMessage = item.constraints[constraintKey];
          enqueueSnackbar(errorMessage || 'Error', {
            variant: 'error',
          });
        });
      });
    }
  };

  const handleError = async (data: any) => {
    if (data) {
      if (data?.message === WEB_SOCKET_EVENT_TYPES.AUTHORIZATION_ERROR) {
        handleAuthError();
      } else {
        enqueueSnackbar(data?.message || 'Error', {
          variant: 'error',
        });
      }
    }
  };

  React.useEffect(() => {
    const callback = (event: string, data: any) => {
      if (event === WEB_SOCKET_EVENT_TYPES.EVENT_REFRESH) {
        handleEventRefresh(data?.data);
      }
      if (event === WEB_SOCKET_EVENT_TYPES.ODDS_REFRESH) {
        handleOddsRefresh(data?.data);
      }
      if (event === WEB_SOCKET_EVENT_TYPES.VALIDATION_ERRORS) {
        handleValidationError(data?.data);
      }
      if (event === WEB_SOCKET_EVENT_TYPES.ERRORS) {
        handleError(data?.data);
      }
      if (event === WEB_SOCKET_EVENT_TYPES.AUTHORIZATION_ERROR) {
        handleAuthError();
      }
    };

    subscribeToAllEvents(JAI_ALAI_NAMESPACE, callback);
  }, [gameDateId, selectedGame]);

  const handlePublishOdds = (projectionType: string) => {
    publishOdds(
      { sport: SPORT_TYPES.JAIALAI, eventId: selectedGame, payload: { projectionType } },
      {
        onSettled: (_, error) => {
          if (!error) {
            enqueueSnackbar('Published odds');
          }
        },
      },
    );
  };

  const exportHandler = () => {
    gameExcelDownloader({
      matchData: selectedMatch,
      oddsExportData: marketOddsDownloadPayload({ odds: values.odds }),
      oddTypeSelected,
      eventInfo: { ...event, traderUpdatedAtDate: data?.traderUpdatedAtDate },
    });
  };

  // if (isFetchingGame) return <div></div>;

  const isCanceledMatch = React.useMemo(() => {
    if (!selectedMatch) return false;

    return +selectedMatch.eventStatus === JaiAlaiStatusValues[JaiAlaiStatusNames.CANCELED];
  }, [selectedMatch]);

  const isForfeitedMatch = React.useMemo(() => {
    if (!selectedMatch) return false;

    return +selectedMatch.eventStatus === JaiAlaiStatusValues[JaiAlaiStatusNames.FORFEIT];
  }, [selectedMatch]);

  const isCompletedEvent = React.useMemo(() => {
    if (!event) return false;

    return +event.eventStatus === EventStatusValues[EventStatusNames.COMPLETED];
  }, [event]);

  React.useEffect(() => {
    if (!selectedMatch) return;

    setIsDisabledMatch(isCompletedEvent || isCanceledMatch || isForfeitedMatch);
  }, [selectedMatch, isCanceledMatch, isForfeitedMatch, isCompletedEvent]);

  React.useEffect(() => {
    if (!selectedMatch) return;
    const isCurrentMatchOutcomeImpossible = impossibleCombos.some(
      ([homeVal, awayVal]) =>
        +selectedMatch.homeTeamMatchScore === homeVal &&
        +selectedMatch.awayTeamMatchScore === awayVal,
    );
    setIsMatchOutcomeImpossible(isCurrentMatchOutcomeImpossible);
  }, [selectedMatch]);

  return (
    <>
      {game?.events?.length > 0 && (
        <>
          <GameScores
            summary={summary}
            events={events}
            // formik={formik}
            // externalUpdateOdds={externalUpdateOdds}
          />
          <Box
            sx={{
              backgroundColor: '#E9ECEF',
              margin: '0 -1.75rem',
              pt: 2,
              pb: 4,
              paddingRight: '1.75rem',
              paddingLeft: '1.75rem',
            }}
          >
            <Slider
              sliderItems={modMatches}
              selectedItem={selectedMatch}
              handleItemSelect={setSelectedGame}
              itemType={'matchCardJaiAlai'}
              gameDateId={gameDateId}
              setFieldValue={setFieldValue}
            />
            <MatchCardWithSlider
              selectedMatch={selectedMatch}
              nextMatch={nextMatch}
              prevMatch={prevMatch}
              formik={formik}
              externalUpdateOdds={externalUpdateOdds}
              gameDateId={gameDateId}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              justifyContent: { xs: 'flex-start', sm: 'space-between' },
              alignItems: 'center',
              mt: 2,
            }}
          >
            <ExportBtn handleOnClick={exportHandler} disabled={isDisabledMatch} />

            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                alignItems: 'center',
                mb: 2,
                gap: '1rem',
              }}
            >
              <OddTypeSwitcher />
              {/* Set showDebugBtn to false before pushing the code */}
              <DebugBtn showDebugBtn={true} />
              <Button
                variant="contained"
                disableElevation
                disabled={isDisabledMatch}
                onClick={() => updateOdds({})}
              >
                Save
              </Button>
            </Box>
          </Box>
          {isForfeitedMatch ? (
            <></>
          ) : (
            <Box mt={-3}>
              <PublishOdds
                clientUpdatedAtDate={values?.clientUpdatedAtDate || ''}
                traderUpdatedAtDate={values?.traderUpdatedAtDate || ''}
                traderUpdatedAtDates={values?.traderUpdatedAtDate || null}
                handlePublishOdds={() => {
                  handlePublishOdds(ODDS_PROJECTION_TYPE.eventWinnerProjections);
                }}
                disablePublish={isDisabledMatch}
              />
            </Box>
          )}
          <Grid
            container
            sx={{
              marginBottom: '1.5rem',
            }}
          ></Grid>
        </>
      )}
      <Markets formik={formik} updateOdds={updateOdds} handlePublishOdds={handlePublishOdds} />
    </>
  );
}
